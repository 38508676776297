import React from "react"
import Layout from "./src/components/layout"
// import "@fontsource/source-sans-pro/300.css"
// import "@fontsource/source-sans-pro/400.css"
// import "@fontsource/source-sans-pro/600.css"
// import "@fontsource/source-sans-pro/700.css"

// custom CSS styles
import "./src/styles/bootstrap-reboot.min.css"
import "./src/styles/swiper.min.css"
import "./src/styles/main.css"
import "./src/styles/common.css"

// const ReactDOM = require("react-dom")

// exports.replaceHydrateFunction = () => {
//   return (element, container, callback) => {
//     ReactDOM.createRoot(container, {
//       hydrate: true,
//       hydrationOptions: { onHydrated: callback },
//     }).render(element)
//   }
// }

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>
}
