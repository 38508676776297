import React from "react"
import CareerLogo from "../../static/images/02.jpg"
import QuoteImg1 from "../../static/images/01.jpg"
import QuoteImg2 from "../../static/images/02.jpg"
import AboutPage from "../../static/images/about.jpg"
import Career1 from "../../static/images/career.jpg"
import karriere1 from "../../static/images/karriere.jpg"
import Career2 from "../../static/images/career2.jpg"
import TeamLogo from "../../static/images/02.jpg"
import ServiceLogo from "../../static/images/service.jpg"

const HomeSvg = () => (
  <>
    <div
      id="headerImg"
      className="headerImg"
      style={{
        backgroundImage: `url(${QuoteImg1})`,
        transform: "scale(0.939583)",
        top: -31.5,
        left: -351.5,
        opacity: 0,
        marginLeft: 100,
      }}
    />
    <div id="headerOverlay" className="headerOverlay top--svg-negative">
      <svg version="1.1" width="1440" height="1050">
        <polygon
          points="26,0 648,368 1270,0"
          fill="#ffffff"
          style={{ transform: "scale(1)" }}
          className="d--none-mobile"
        ></polygon>
        <polygon
          points="-308,0 -308,589 688,0"
          fill="#ffffff"
          style={{ transform: "scale(1)" }}
          className="d--none-mobile"
        ></polygon>
        <polygon
          points="1042,1051 1792,1051 1792,620"
          fill="#ffffff"
          style={{ transform: "scale(1)" }}
        ></polygon>
        <polygon
          points="-308,1051 1045,1051 1045,775 -308,0"
          fill="#ffffff"
          style={{ transform: "scale(1)" }}
          className="d--none-mobile"
        ></polygon>
        <polygon
          points="842,877 686,1000 1176,722"
          fill="#e40036"
          filter="url(#SvgjsFilter1003)"
          opacity="1"
          style={{ transform: "scale(1)" }}
        ></polygon>
        <defs>
          <filter id="SvgjsFilter1003">
            <feOffset
              id="SvgjsFeOffset1000"
              result="SvgjsFeOffset1000"
              in="SourceAlpha"
              dy="-5"
              dx="-5"
            ></feOffset>
            <feGaussianBlur
              id="SvgjsFeGaussianBlur1001"
              result="SvgjsFeGaussianBlur1001"
              in="SvgjsFeOffset1000"
              stdDeviation="20 20"
            ></feGaussianBlur>
            <feBlend
              id="SvgjsFeBlend1002"
              result="SvgjsFeBlend1002"
              in="SourceGraphic"
              in2="SvgjsFeGaussianBlur1001"
            ></feBlend>
          </filter>
        </defs>
        <polygon
          points="264,192 -228,477 686,1000 1176,722"
          fill="#e40036"
          opacity="1"
          style={{ transform: 1 }}
        ></polygon>
      </svg>
    </div>
  </>
)

const CareerPlatformSvg = () => (
  <>
    {/* <div
      id="headerImgVacancy"
      className="headerImg"
      style={{ backgroundImage: `url(${QuoteImg2})`, display: "none" }}
    ></div> */}
    <div id="headerOverlayVacancy" className="headerOverlay">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="1440"
        height="1050"
      >
        <polygon
          points="1560,1200 -20,845 1599,-88 3159,822"
          fill="#f2f3f4"
          data-noscale="mobile"
          style={{ transform: "scale(1)" }}
        ></polygon>
        <polygon
          points="53,440 203,350 53,260"
          style={{ transform: "scale(1)" }}
          fill="#e40036"
        ></polygon>
        <polygon
          points="340,785 205,945 720,650"
          fill="#ffffff"
          style={{ transform: "scale(1)" }}
          filter="url(#SvgjsFilter1007)"
        ></polygon>
        <defs>
          <filter id="SvgjsFilter1007">
            <feOffset
              id="SvgjsFeOffset1004"
              result="SvgjsFeOffset1004"
              in="SourceAlpha"
              dy="-5"
              dx="-5"
            ></feOffset>
            <feGaussianBlur
              id="SvgjsFeGaussianBlur1005"
              result="SvgjsFeGaussianBlur1005"
              in="SvgjsFeOffset1004"
              stdDeviation="20 20"
            ></feGaussianBlur>
            <feBlend
              id="SvgjsFeBlend1006"
              result="SvgjsFeBlend1006"
              in="SourceGraphic"
              in2="SvgjsFeGaussianBlur1005"
            ></feBlend>
          </filter>
        </defs>
        <image
          width="1196"
          height="690"
          xlinkHref={CareerLogo}
          // loading="lazy"
          x="-390"
          y="310"
          clipPath="polygon(50% 5%, 93% 50%, 50% 100%, 0% 50%)"
        ></image>
      </svg>
    </div>
  </>
)

const AboutSvg = () => (
  <>
    {/* <div
      id="headerImg"
      className="headerImg"
      style={{ backgroundImage: `url(${AboutPage})`, display: "none" }}
    ></div> */}
    <div
      id="headerOverlay"
      className="headerOverlay no--margin-menu"
      style={{ marginTop: "70px" }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="1440"
        height="1050"
      >
        <polygon
          points="970,950 180,495 1459,-251 2249,203"
          fill="#f2f3f4"
          data-noscale="mobile"
          style={{ transform: 1 }}
        ></polygon>
        <polygon
          points="317,416 202,350 237,329 352,395"
          fill="#f2f3f4"
          data-noscale="mobile"
          style={{ transform: 1 }}
        ></polygon>
        <polygon
          points="1388,1075 1238,985 1388,895"
          fill="#e40036"
          style={{ transform: 1 }}
        ></polygon>
        <polygon
          points="53,440 203,350 53,260"
          fill="#e40036"
          style={{ transform: 1 }}
        ></polygon>
        <polygon
          points="340,785 205,945 720,650"
          fill="#ffffff"
          filter="url(#SvgjsFilter1008)"
          style={{ transform: 1 }}
        ></polygon>
        <defs>
          <filter id="SvgjsFilter1008">
            <feOffset
              id="SvgjsFeOffset1005"
              result="SvgjsFeOffset1005"
              in="SourceAlpha"
              dy="-5"
              dx="-5"
            ></feOffset>
            <feGaussianBlur
              id="SvgjsFeGaussianBlur1006"
              result="SvgjsFeGaussianBlur1006"
              in="SvgjsFeOffset1005"
              stdDeviation="20 20"
            ></feGaussianBlur>
            <feBlend
              id="SvgjsFeBlend1007"
              result="SvgjsFeBlend1007"
              in="SourceGraphic"
              in2="SvgjsFeGaussianBlur1006"
            ></feBlend>
          </filter>
          <clipPath id="SvgjsClipPath1009">
            <polygon
              points="203,350 -317,650 203,950 723,650"
              data-noscale="true"
            ></polygon>
          </clipPath>
        </defs>
        <image
          href={AboutPage}
          width="1196"
          height="690"
          x="-390"
          loading="lazy"
          y="310"
          clipPath='url("#SvgjsClipPath1009")'
        ></image>
      </svg>
    </div>
  </>
)

const CareerSvg = language => (
  <div className="header header-typ3 no--margin-menu">
    {/* <div
      id="headerImg"
      className="headerImg"
      style={{
        backgroundImage: `url(${language.lan === "en" ? Career1 : karriere1})`,
        display: "none",
      }}
    ></div> */}
    {/* <div
      id="headerImg2"
      className="headerImg2"
      style={{ backgroundImage: `url(${Career2})`, display: "none" }}
    ></div> */}
    <div
      style={{ marginTop: "80px" }}
      id="headerOverlay"
      className="headerOverlay no--margin-menu career-image-set"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="1440"
        height="1050"
      >
        <polygon
          points="317,416 202,350 237,329 352,395"
          fill="#f2f3f4"
          data-noscale="mobile"
          style={{ transform: 1 }}
        ></polygon>
        <polygon
          points="1388,1075 1238,985 1388,895"
          fill="#e40036"
          style={{ transform: 1 }}
        ></polygon>
        <polygon
          points="53,440 203,350 53,260"
          fill="#e40036"
          style={{ transform: 1 }}
        ></polygon>
        <polygon
          points="340,785 205,945 720,650"
          fill="#ffffff"
          filter="url(#SvgjsFilter1015)"
          style={{ transform: 1 }}
        ></polygon>
        <defs>
          <filter id="SvgjsFilter1015">
            <feOffset
              id="SvgjsFeOffset1012"
              result="SvgjsFeOffset1012"
              in="SourceAlpha"
              dy="-5"
              dx="-5"
            ></feOffset>
            <feGaussianBlur
              id="SvgjsFeGaussianBlur1013"
              result="SvgjsFeGaussianBlur1013"
              in="SvgjsFeOffset1012"
              stdDeviation="20 20"
            ></feGaussianBlur>
            <feBlend
              id="SvgjsFeBlend1014"
              result="SvgjsFeBlend1014"
              in="SourceGraphic"
              in2="SvgjsFeGaussianBlur1013"
            ></feBlend>
          </filter>
          <clipPath id="SvgjsClipPath1016">
            <polygon
              points="970,950 180,495 1459,-251 2249,203"
              fill="#f2f3f4"
              data-noscale="mobile"
              style={{ transform: 1 }}
            ></polygon>
          </clipPath>
          <clipPath id="SvgjsClipPath1017">
            <polygon
              points="203,350 -317,650 203,950 723,650"
              data-noscale="true"
            ></polygon>
          </clipPath>
        </defs>
        <image
          width="2080"
          href={Career2}
          height="1200"
          x="168"
          y="-240"
          clipPath='url("#SvgjsClipPath1016")'
        ></image>
        <image
          width="1196"
          href={language.lan === "en" ? Career1 : karriere1}
          height="690"
          loading="lazy"
          x="-390"
          y="310"
          clipPath='url("#SvgjsClipPath1017")'
        ></image>
      </svg>
    </div>
  </div>
)

const TeamSvg = () => (
  <>
    {/* <div
      id="headerImg"
      className="headerImg"
      style={{ backgroundImage: `url(${TeamLogo})`, display: "none" }}
    ></div> */}
    <div id="headerOverlay" className="headerOverlay no--margin-menu">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="1440"
        height="1050"
      >
        <polygon
          points="970,950 180,495 1459,-251 2249,203"
          fill="#f2f3f4"
          data-noscale="mobile"
          style={{ transform: 1 }}
        ></polygon>
        <polygon
          points="317,416 202,350 237,329 352,395"
          fill="#f2f3f4"
          data-noscale="mobile"
          style={{ transform: 1 }}
        ></polygon>
        <polygon
          points="1388,1075 1238,985 1388,895"
          fill="#e40036"
          style={{ transform: 1 }}
        ></polygon>
        <polygon
          points="53,440 203,350 53,260"
          fill="#e40036"
          style={{ transform: 1 }}
        ></polygon>
        <polygon
          points="340,785 205,945 720,650"
          fill="#ffffff"
          filter="url(#SvgjsFilter1008)"
          style={{ transform: 1 }}
        ></polygon>
        <defs>
          <filter id="SvgjsFilter1008">
            <feOffset
              id="SvgjsFeOffset1005"
              result="SvgjsFeOffset1005"
              in="SourceAlpha"
              dy="-5"
              dx="-5"
            ></feOffset>
            <feGaussianBlur
              id="SvgjsFeGaussianBlur1006"
              result="SvgjsFeGaussianBlur1006"
              in="SvgjsFeOffset1005"
              stdDeviation="20 20"
            ></feGaussianBlur>
            <feBlend
              id="SvgjsFeBlend1007"
              result="SvgjsFeBlend1007"
              in="SourceGraphic"
              in2="SvgjsFeGaussianBlur1006"
            ></feBlend>
          </filter>
          <clipPath id="SvgjsClipPath1009">
            <polygon
              points="203,350 -317,650 203,950 723,650"
              data-noscale="true"
            ></polygon>
          </clipPath>
        </defs>
        <image
          xlinkHref={TeamLogo}
          href={TeamLogo}
          width="1196"
          height="690"
          x="-390"
          y="310"
          loading="lazy"
          clipPath='url("#SvgjsClipPath1009")'
        ></image>
      </svg>
    </div>
  </>
)

const ServiceSvg = () => (
  <>
    {/* <div
      id="headerImg"
      className="headerImg"
      style={{ backgroundImage: `url(${ServiceLogo})`, display: "none" }}
    ></div> */}
    <div
      id="headerOverlay"
      className="headerOverlay no--margin-menu"
      style={{ marginTop: "70px" }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="1440"
        height="1050"
      >
        <polygon
          points="970,950 180,495 1459,-251 2249,203"
          fill="#f2f3f4"
          data-noscale="mobile"
          style={{ transform: 1 }}
        ></polygon>
        <polygon
          points="317,416 202,350 237,329 352,395"
          fill="#f2f3f4"
          data-noscale="mobile"
          style={{ transform: 1 }}
        ></polygon>
        <polygon
          points="1388,1075 1238,985 1388,895"
          fill="#e40036"
          style={{ transform: 1 }}
        ></polygon>
        <polygon
          points="53,440 203,350 53,260"
          fill="#e40036"
          style={{ transform: 1 }}
        ></polygon>
        <polygon
          points="340,785 205,945 720,650"
          fill="#ffffff"
          filter="url(#SvgjsFilter1013)"
          style={{ transform: 1 }}
        ></polygon>
        <defs>
          <filter id="SvgjsFilter1013">
            <feOffset
              id="SvgjsFeOffset1010"
              result="SvgjsFeOffset1010"
              in="SourceAlpha"
              dy="-5"
              dx="-5"
            ></feOffset>
            <feGaussianBlur
              id="SvgjsFeGaussianBlur1011"
              result="SvgjsFeGaussianBlur1011"
              in="SvgjsFeOffset1010"
              stdDeviation="20 20"
            ></feGaussianBlur>
            <feBlend
              id="SvgjsFeBlend1012"
              result="SvgjsFeBlend1012"
              in="SourceGraphic"
              in2="SvgjsFeGaussianBlur1011"
            ></feBlend>
          </filter>
          <clipPath id="SvgjsClipPath1014">
            <polygon
              points="203,350 -317,650 203,950 723,650"
              data-noscale="true"
            ></polygon>
          </clipPath>
        </defs>
        <image
          width="1196"
          href={ServiceLogo}
          height="690"
          loading="lazy"
          x="-390"
          y="310"
          clipPath='url("#SvgjsClipPath1014")'
        ></image>
      </svg>
    </div>
  </>
)

const In = () => (
  <svg
    width="70"
    height="70"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="96" height="96" rx="10" fill="#1E85BC" />
    <path
      d="M31.0306 79H18.2303V37.6666H31.0306V79ZM24.5694 32.2537C20.389 32.2537 17 28.8388 17 24.627C17 20.4147 20.3895 17 24.5694 17C28.7494 17 32.1384 20.4147 32.1384 24.627C32.1386 28.839 28.7496 32.2537 24.5694 32.2537ZM78.9998 79H66.2615V57.3034C66.2615 51.3526 64 48.0309 59.2922 48.0309C54.1686 48.0309 51.4919 51.4905 51.4919 57.3034V79H39.2149V37.6666H51.4919V43.2335C51.4919 43.2335 55.1848 36.4058 63.9536 36.4058C72.7227 36.4058 79 41.7574 79 52.8283L78.9998 79Z"
      fill="white"
    />
  </svg>
)

const Xin = () => (
  <svg
    width="70"
    height="70"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="96" height="96" rx="10" fill="#005A5F" />
    <g clipPath="url(#clip0)">
      <path
        d="M25.8301 29.2453C25.2896 29.2453 24.8348 29.4344 24.6053 29.8043C24.3687 30.1859 24.4045 30.6779 24.657 31.1757L30.7288 41.6417C30.7391 41.6615 30.7391 41.6742 30.7288 41.6925L21.1873 58.4586C20.9385 58.9521 20.9507 59.4478 21.1873 59.8303C21.4164 60.1983 21.8202 60.4398 22.3613 60.4398H31.3412C32.6836 60.4398 33.3315 59.5377 33.7904 58.7131C33.7904 58.7131 43.1217 42.2799 43.485 41.642C43.4483 41.5831 37.3114 30.9221 37.3114 30.9221C36.8634 30.1295 36.1888 29.245 34.8115 29.245H25.8301V29.2453Z"
        fill="white"
      />
      <path
        d="M63.5564 17.0006C62.2149 17.0006 61.6337 17.8419 61.152 18.7037C61.152 18.7037 41.8063 52.8657 41.1687 53.9873C41.2014 54.0481 53.928 77.2965 53.928 77.2965C54.3739 78.0895 55.0616 78.9997 56.4373 78.9997H65.4066C65.9477 78.9997 66.3704 78.7969 66.5983 78.4283C66.8381 78.047 66.8312 77.5427 66.58 77.0463L53.9203 54.0149C53.9143 54.0063 53.9112 53.9961 53.9112 53.9856C53.9112 53.9751 53.9143 53.9649 53.9203 53.9563L73.8024 18.9511C74.0524 18.457 74.0577 17.9529 73.8214 17.571C73.5923 17.2027 73.1682 16.9997 72.6271 16.9997H63.5555V17.0003H63.5564V17.0006Z"
        fill="#D4D600"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="53"
          height="62"
          fill="white"
          transform="translate(21 17)"
        />
      </clipPath>
    </defs>
  </svg>
)

const In2 = () => (
  <svg
    width="50"
    height="50"
    style={{ border: "3px solid white", borderRadius: "26px" }}
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="96" height="96" rx="10" fill="#1E85BC" />
    <path
      d="M31.0306 79H18.2303V37.6666H31.0306V79ZM24.5694 32.2537C20.389 32.2537 17 28.8388 17 24.627C17 20.4147 20.3895 17 24.5694 17C28.7494 17 32.1384 20.4147 32.1384 24.627C32.1386 28.839 28.7496 32.2537 24.5694 32.2537ZM78.9998 79H66.2615V57.3034C66.2615 51.3526 64 48.0309 59.2922 48.0309C54.1686 48.0309 51.4919 51.4905 51.4919 57.3034V79H39.2149V37.6666H51.4919V43.2335C51.4919 43.2335 55.1848 36.4058 63.9536 36.4058C72.7227 36.4058 79 41.7574 79 52.8283L78.9998 79Z"
      fill="white"
    />
  </svg>
)

const Xin2 = () => (
  <svg
    width="50"
    height="50"
    style={{ border: "3px solid white", borderRadius: "26px" }}
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="96" height="96" rx="10" fill="#005A5F" />
    <g clipPath="url(#clip0)">
      <path
        d="M25.8301 29.2453C25.2896 29.2453 24.8348 29.4344 24.6053 29.8043C24.3687 30.1859 24.4045 30.6779 24.657 31.1757L30.7288 41.6417C30.7391 41.6615 30.7391 41.6742 30.7288 41.6925L21.1873 58.4586C20.9385 58.9521 20.9507 59.4478 21.1873 59.8303C21.4164 60.1983 21.8202 60.4398 22.3613 60.4398H31.3412C32.6836 60.4398 33.3315 59.5377 33.7904 58.7131C33.7904 58.7131 43.1217 42.2799 43.485 41.642C43.4483 41.5831 37.3114 30.9221 37.3114 30.9221C36.8634 30.1295 36.1888 29.245 34.8115 29.245H25.8301V29.2453Z"
        fill="white"
      />
      <path
        d="M63.5564 17.0006C62.2149 17.0006 61.6337 17.8419 61.152 18.7037C61.152 18.7037 41.8063 52.8657 41.1687 53.9873C41.2014 54.0481 53.928 77.2965 53.928 77.2965C54.3739 78.0895 55.0616 78.9997 56.4373 78.9997H65.4066C65.9477 78.9997 66.3704 78.7969 66.5983 78.4283C66.8381 78.047 66.8312 77.5427 66.58 77.0463L53.9203 54.0149C53.9143 54.0063 53.9112 53.9961 53.9112 53.9856C53.9112 53.9751 53.9143 53.9649 53.9203 53.9563L73.8024 18.9511C74.0524 18.457 74.0577 17.9529 73.8214 17.571C73.5923 17.2027 73.1682 16.9997 72.6271 16.9997H63.5555V17.0003H63.5564V17.0006Z"
        fill="#D4D600"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="53"
          height="62"
          fill="white"
          transform="translate(21 17)"
        />
      </clipPath>
    </defs>
  </svg>
)

const Profile = () => (
  <svg
    width="100%"
    height="570"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="200" height="200" fill="#DEDEDE" />
    <path
      d="M145.5 89.5065C145.5 114.629 125.129 135.007 100 135.007C74.871 135.007 54.5 114.629 54.5 89.5065C54.5 64.371 74.871 44 100 44C125.129 44 145.5 64.371 145.5 89.5065V89.5065ZM135.113 136.176C125.324 143.567 113.182 148.006 100 148.006C86.805 148.006 74.6565 143.554 64.8545 136.163C38.3865 147.818 22 184.108 22 200H178C178 184.25 161.1 147.961 135.113 136.176V136.176Z"
      fill="#555555"
    />
  </svg>
)

const NoData = () => (
  <svg
    style={{ alignSelf: "center" }}
    id="b21613c9-2bf0-4d37-bef0-3b193d34fc5d"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="200"
    height="200"
    viewBox="0 0 647.63626 632.17383"
  >
    <path
      d="M687.3279,276.08691H512.81813a15.01828,15.01828,0,0,0-15,15v387.85l-2,.61005-42.81006,13.11a8.00676,8.00676,0,0,1-9.98974-5.31L315.678,271.39691a8.00313,8.00313,0,0,1,5.31006-9.99l65.97022-20.2,191.25-58.54,65.96972-20.2a7.98927,7.98927,0,0,1,9.99024,5.3l32.5498,106.32Z"
      transform="translate(-276.18187 -133.91309)"
      fill="#f2f2f2"
    />
    <path
      d="M725.408,274.08691l-39.23-128.14a16.99368,16.99368,0,0,0-21.23-11.28l-92.75,28.39L380.95827,221.60693l-92.75,28.4a17.0152,17.0152,0,0,0-11.28028,21.23l134.08008,437.93a17.02661,17.02661,0,0,0,16.26026,12.03,16.78926,16.78926,0,0,0,4.96972-.75l63.58008-19.46,2-.62v-2.09l-2,.61-64.16992,19.65a15.01489,15.01489,0,0,1-18.73-9.95l-134.06983-437.94a14.97935,14.97935,0,0,1,9.94971-18.73l92.75-28.4,191.24024-58.54,92.75-28.4a15.15551,15.15551,0,0,1,4.40966-.66,15.01461,15.01461,0,0,1,14.32032,10.61l39.0498,127.56.62012,2h2.08008Z"
      transform="translate(-276.18187 -133.91309)"
      fill="#3f3d56"
    />
    <path
      d="M398.86279,261.73389a9.0157,9.0157,0,0,1-8.61133-6.3667l-12.88037-42.07178a8.99884,8.99884,0,0,1,5.9712-11.24023l175.939-53.86377a9.00867,9.00867,0,0,1,11.24072,5.9707l12.88037,42.07227a9.01029,9.01029,0,0,1-5.9707,11.24072L401.49219,261.33887A8.976,8.976,0,0,1,398.86279,261.73389Z"
      transform="translate(-276.18187 -133.91309)"
      fill="#e40036"
    />
    <circle cx="190.15351" cy="24.95465" r="20" fill="#e40036" />
    <circle cx="190.15351" cy="24.95465" r="12.66462" fill="#fff" />
    <path
      d="M878.81836,716.08691h-338a8.50981,8.50981,0,0,1-8.5-8.5v-405a8.50951,8.50951,0,0,1,8.5-8.5h338a8.50982,8.50982,0,0,1,8.5,8.5v405A8.51013,8.51013,0,0,1,878.81836,716.08691Z"
      transform="translate(-276.18187 -133.91309)"
      fill="#e6e6e6"
    />
    <path
      d="M723.31813,274.08691h-210.5a17.02411,17.02411,0,0,0-17,17v407.8l2-.61v-407.19a15.01828,15.01828,0,0,1,15-15H723.93825Zm183.5,0h-394a17.02411,17.02411,0,0,0-17,17v458a17.0241,17.0241,0,0,0,17,17h394a17.0241,17.0241,0,0,0,17-17v-458A17.02411,17.02411,0,0,0,906.81813,274.08691Zm15,475a15.01828,15.01828,0,0,1-15,15h-394a15.01828,15.01828,0,0,1-15-15v-458a15.01828,15.01828,0,0,1,15-15h394a15.01828,15.01828,0,0,1,15,15Z"
      transform="translate(-276.18187 -133.91309)"
      fill="#3f3d56"
    />
    <path
      d="M801.81836,318.08691h-184a9.01015,9.01015,0,0,1-9-9v-44a9.01016,9.01016,0,0,1,9-9h184a9.01016,9.01016,0,0,1,9,9v44A9.01015,9.01015,0,0,1,801.81836,318.08691Z"
      transform="translate(-276.18187 -133.91309)"
      fill="#e40036"
    />
    <circle cx="433.63626" cy="105.17383" r="20" fill="#e40036" />
    <circle cx="433.63626" cy="105.17383" r="12.18187" fill="#fff" />
  </svg>
)

const Imprint = () => (
  <>
    {/* <div
      id="headerImg"
      className="headerImg"
      style={{ backgroundImage: `url(${QuoteImg1})`, display: "none" }}
    >
      {" "}
    </div> */}
    <div id="headerOverlay" className="headerOverlay no--margin-menu">
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <polygon
          points="970,950 180,495 1459,-251 2249,203"
          fill="#f2f3f4"
          data-noscale="mobile"
          style={{ transform: "scale(1)" }}
        ></polygon>
        <polygon
          points="317,416 202,350 237,329 352,395"
          fill="#f2f3f4"
          data-noscale="mobile"
          style={{ transform: "scale(1)" }}
        ></polygon>
        <polygon
          points="1388,1075 1238,985 1388,895"
          fill="#e40036"
          style={{ transform: "scale(1)" }}
        ></polygon>
        <polygon
          points="53,440 203,350 53,260"
          fill="#e40036"
          style={{ transform: "scale(1)" }}
        ></polygon>
        <polygon
          points="340,785 205,945 720,650"
          fill="#ffffff"
          filter="url(#SvgjsFilter1003)"
          style={{ transform: "scale(1)" }}
        ></polygon>
        <defs>
          <filter id="SvgjsFilter1003">
            <feOffset
              id="SvgjsFeOffset1000"
              result="SvgjsFeOffset1000"
              in="SourceAlpha"
              dy="-5"
              dx="-5"
            ></feOffset>
            <feGaussianBlur
              id="SvgjsFeGaussianBlur1001"
              result="SvgjsFeGaussianBlur1001"
              in="SvgjsFeOffset1000"
              stdDeviation="20 20"
            ></feGaussianBlur>
            <feBlend
              id="SvgjsFeBlend1002"
              result="SvgjsFeBlend1002"
              in="SourceGraphic"
              in2="SvgjsFeGaussianBlur1001"
            ></feBlend>
          </filter>
          <clipPath id="SvgjsClipPath1004">
            <polygon
              points="203,350 -317,650 203,950 723,650"
              data-noscale="true"
            ></polygon>
          </clipPath>
        </defs>
        <image
          width="1196"
          height="690"
          xlinkHref={QuoteImg1}
          loading="lazy"
          x="-390"
          y="310"
          clipPath='url("#SvgjsClipPath1004")'
        ></image>
      </svg>
    </div>
  </>
)

const PrivacyPolicySVG = () => (
  <>
    {/* <div
      id="headerImg"
      className="headerImg"
      style={{ backgroundImage: `url(${QuoteImg1})`, display: "none" }}
    ></div> */}
    <div id="headerOverlay" className="headerOverlay no--margin-menu">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="1440"
        height="1050"
      >
        <polygon
          points="970,950 180,495 1459,-251 2249,203"
          fill="#f2f3f4"
          data-noscale="mobile"
          style={{ transform: "scale(1)" }}
        ></polygon>
        <polygon
          points="317,416 202,350 237,329 352,395"
          fill="#f2f3f4"
          data-noscale="mobile"
          style={{ transform: "scale(1)" }}
        ></polygon>
        <polygon
          points="1388,1075 1238,985 1388,895"
          fill="#e40036"
          style={{ transform: "scale(1)" }}
        ></polygon>
        <polygon
          points="53,440 203,350 53,260"
          fill="#e40036"
          style={{ transform: "scale(1)" }}
        ></polygon>
        <polygon
          points="340,785 205,945 720,650"
          fill="#ffffff"
          filter="url(#SvgjsFilter1008)"
          style={{ transform: "scale(1)" }}
        ></polygon>
        <defs>
          <filter id="SvgjsFilter1008">
            <feOffset
              id="SvgjsFeOffset1005"
              result="SvgjsFeOffset1005"
              in="SourceAlpha"
              dy="-5"
              dx="-5"
            ></feOffset>
            <feGaussianBlur
              id="SvgjsFeGaussianBlur1006"
              result="SvgjsFeGaussianBlur1006"
              in="SvgjsFeOffset1005"
              stdDeviation="20 20"
            ></feGaussianBlur>
            <feBlend
              id="SvgjsFeBlend1007"
              result="SvgjsFeBlend1007"
              in="SourceGraphic"
              in2="SvgjsFeGaussianBlur1006"
            ></feBlend>
          </filter>
          <clipPath id="SvgjsClipPath1009">
            <polygon
              points="203,350 -317,650 203,950 723,650"
              data-noscale="true"
            ></polygon>
          </clipPath>
        </defs>
        <image
          width="1196"
          height="690"
          href={QuoteImg1}
          loading="lazy"
          x="-390"
          y="310"
          clipPath='url("#SvgjsClipPath1009")'
        ></image>
      </svg>
    </div>
  </>
)

export {
  CareerPlatformSvg,
  AboutSvg,
  CareerSvg,
  TeamSvg,
  ServiceSvg,
  HomeSvg,
  In,
  Xin,
  In2,
  Xin2,
  Profile,
  NoData,
  Imprint,
  PrivacyPolicySVG,
}
