import React, { useEffect } from "react"
import Footer from "./footer"
import Header from "./header"
import Map from "./map"

const Layout = ({ children }) => {
  useEffect(() => {
    document.body.className =
      children.key === "/de/" || children.key === "/en/" ? "page-home" : ""
  }, [children.key])

  return (
    <>
      <Header language={children} />
      {children}
      <Footer language={children} />
      <Map />
    </>
  )
}

export default Layout
