import React, { useState, useEffect } from "react"
import WhiteLogo from "../images/logoWhite.png"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { useStaticQuery, graphql, Link } from "gatsby"
import { In2, Xin2 } from "../svgs/svg"
import { Email, Phone, MyLocation, Parking } from "../shared/icons/icons"

const Footer = ({ language }) => {
  useEffect(() => {
    setEmail("")
    setError("")
  }, [language.key])
  const lan = language.props
    ? language.props.pageContext
      ? language.props.pageContext.locale
      : "en"
    : "en"
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          email
          phone
          fax
          phone_2
          fax_2
          linkedIn
          xing
          en {
            subscribeToOurNewsletter
            subscribe
            parkingAddress
          }
          de {
            subscribeToOurNewsletter
            subscribe
            parkingAddress
          }
        }
      }
    }
  `)

  const { siteMetadata } = site

  const handleChange = event => {
    setError("")
    setEmail(event.target.value)
  }

  const handleSubmit = async () => {
    setLoading(true)
    const result = await addToMailchimp(email)
    if (result.result === "error") {
      if (result.msg === "The email you entered is not valid.") {
        setError(
          lan === "en"
            ? "The email you entered is not valid"
            : "Die eingegebene E-Mail ist ungültig"
        )
      } else {
        setError(
          lan === "en"
            ? "You have already subscribed."
            : "Sie haben sich bereits abonniert."
        )
      }
      setLoading(false)
    } else {
      setEmail("")
      setError("")
      setLoading(false)
      alert("You have been subscribed!")
    }
  }

  return (
    <div>
      <footer
        className={
          language.props &&
          language.props.location &&
          (language.props.location.pathname === "/en/" ||
            language.props.location.pathname === "/de/")
            ? "home-page-footer"
            : ""
        }
      >
        <div>
          <div className="news-form">
            <span className="news-text">
              {lan &&
                site.siteMetadata &&
                site.siteMetadata[lan].subscribeToOurNewsletter}
              :
            </span>
            <div>
              <div className="d-flex flex-direction-column height-80">
                <input
                  className="mt-20 mr-30"
                  type="email"
                  value={email}
                  onChange={handleChange}
                  placeholder="Email"
                />
                <span className="color-red">{error}</span>
              </div>
              <button
                className={`${
                  loading ? "cursor-not-allowed bg-grey" : ""
                } news-submit-button ml--15 mt-20`}
                onClick={loading ? () => {} : handleSubmit}
              >
                {lan && site.siteMetadata && site.siteMetadata[lan].subscribe}
              </button>
            </div>
          </div>
          <div className="icons">
            <div>
              <a href={siteMetadata.linkedIn} target="_blank" rel="noreferrer">
                <In2 />
              </a>
            </div>
            <div>
              <a href={siteMetadata.xing} target="_blank" rel="noreferrer">
                <Xin2 />
              </a>
            </div>
          </div>
        </div>
        <div className="copy">
          <div className="address-info">
            <div>
              <div className="svg-icon">
                <MyLocation height="24" width="24" />
              </div>
              <div className="address-row">
                <b>{`${
                  lan === "en" ? "Office" : "Standort"
                } Offenbach am Main`}</b>
                <br />
                Hafeninsel 11
                <br />
                63067 Offenbach am Main
              </div>
            </div>
            <div>
              <div className="svg-icon">
                <Phone height="24" width="24" />
              </div>
              <div className="address-row">
                {lan === "en" ? "Phone" : "Telefon"}:{" "}
                <b>{siteMetadata.phone}</b>
                <br />
                Fax: <b>{siteMetadata.fax}</b>
              </div>
            </div>
            <div>
              <div className="svg-icon">
                <MyLocation height="24" width="24" />
              </div>
              <div className="address-row">
                <b>{`${
                  lan === "en" ? "Office" : "Standort"
                } Frankfurt am Main`}</b>
                <br />
                Neue Mainzer Straße 6 - 10
                <br />
                60311 Frankfurt am Main
              </div>
            </div>
            <div>
              <div className="svg-icon">
                <Phone height="24" width="24" />
              </div>
              <div className="address-row">
                {lan === "en" ? "Phone" : "Telefon"}:{" "}
                <b>{siteMetadata.phone_2}</b>
                <br />
                Fax: <b>{siteMetadata.fax_2}</b>
              </div>
            </div>
            <div className="single-line">
              <div className="svg-icon">
                <Email height="24" width="24" />
              </div>
              <div className="address-row">
                E-Mail:{" "}
                <a
                  href="mailto:info@haackschubert.de"
                  target="_blank"
                  rel="noreferrer"
                >
                  {siteMetadata.email}
                </a>
              </div>
            </div>
            <div className="three-line">
              <div className="svg-icon">
                <Parking height="24" width="24" />
              </div>
              <div className="address-row">
                <b>
                  {lan === "en" ? "Parking facilities" : "Parkmöglichkeiten"}
                </b>{" "}
                {lan &&
                  site.siteMetadata &&
                  site.siteMetadata[lan].parkingAddress}
              </div>
            </div>
          </div>
          <div>
            <div className="footerLogo">
              <img
                loading="lazy"
                src={WhiteLogo}
                height="73"
                width="304"
                alt="White Logo"
              />
              <br />
            </div>
            <strong>
              © {new Date().getFullYear()} HaackSchubert
              Partnerschaftsgesellschaft mbB
            </strong>
            <br />
            <br />
            {lan === "en"
              ? "Lawyers, Tax Consultants and Auditors."
              : "Rechtsanwälte, Steuerberater und Wirtschaftsprüfer."}
            <br />
            <br />
            {lan === "en" ? "All rights reserved." : "Alle Rechte vorbehalten."}
          </div>
        </div>
        <div className="metamenu">
          <ul>
            <li>
              <Link to={`/${lan}/imprint/`}>
                {lan === "en" ? "Imprint" : "Impressum"}
              </Link>
            </li>
            <li>
              <Link to={`/${lan}/privacyPolicy/`}>
                {lan === "en" ? "Privacy Policy" : "Datenschutzerklärung"}
              </Link>
            </li>
          </ul>
        </div>
      </footer>
      <div
        className={
          language.props &&
          language.props.location &&
          (language.props.location.pathname === "/en/" ||
            language.props.location.pathname === "/de/")
            ? "address-info address-infooo"
            : "address-info"
        }
      >
        <h3
          style={{
            marginTop: 0,
          }}
        >
          HaackSchubert Partnerschaftsgesellschaft mbB
        </h3>
        <p>
          {lan === "en"
            ? "Lawyers, Tax Consultants and Auditors."
            : "Rechtsanwälte, Steuerberater und Wirtschaftsprüfer."}
        </p>
        <div>
          <div className="svg-icon">
            <MyLocation height="33" width="33" />
          </div>
          <div className="address-row">
            <b>{`${lan === "en" ? "Office" : "Standort"} Offenbach am Main`}</b>
            <br />
            Hafeninsel 11
            <br />
            63067 Offenbach am Main
          </div>
        </div>
        <div>
          <div className="svg-icon">
            <Phone height="33" width="33" />
          </div>
          <div className="address-row">
            {lan === "en" ? "Phone" : "Telefon"}: <b>{siteMetadata.phone}</b>
            <br />
            Fax: <b>{siteMetadata.fax}</b>
          </div>
        </div>
        <div>
          <div className="svg-icon">
            <MyLocation height="33" width="33" />
          </div>
          <div className="address-row">
            <b>{`${lan === "en" ? "Office" : "Standort"} Frankfurt am Main`}</b>
            <br />
            Neue Mainzer Straße 6 - 10
            <br />
            60311 Frankfurt am Main
          </div>
        </div>
        <div>
          <div className="svg-icon">
            <Phone height="33" width="33" />
          </div>
          <div className="address-row">
            {lan === "en" ? "Phone" : "Telefon"}: <b>{siteMetadata.phone_2}</b>
            <br />
            Fax: <b>{siteMetadata.fax_2}</b>
          </div>
        </div>
        <div className="single-line">
          <div className="svg-icon">
            <Email height="33" width="33" />
          </div>
          <div className="address-row">
            E-Mail:{" "}
            <a
              href="mailto:info@haackschubert.de"
              target="_blank"
              rel="noreferrer"
            >
              {siteMetadata.email}
            </a>
          </div>
        </div>
        <div className="three-line">
          <div className="svg-icon">
            <Parking height="33" width="33" />
          </div>
          <div className="address-row">
            <b>{lan === "en" ? "Parking facilities" : "Parkmöglichkeiten"}</b>{" "}
            {lan && site.siteMetadata && site.siteMetadata[lan].parkingAddress}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
