import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import logo from "../images/logo.png"
import { Menu, Close } from "../shared/icons/icons"

const Header = ({ language }) => {
  const [showMenu, setShowMenu] = useState(false)
  const [showServices, setShowServices] = useState(false)
  const [slug, setSlug] = useState("legal-advice-notaries")
  const [newsSlug, setNewsSlug] = useState("publications")
  const [showNews, setShowNews] = useState(false)
  const lan = language.props
    ? language.props.pageContext
      ? language.props.pageContext.locale
      : "en"
    : "en"

  const { site, services } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          en {
            start
            aboutUs
            forms
            webinars
            news
            team
            services
            career
            contact
          }
          de {
            start
            aboutUs
            forms
            webinars
            news
            team
            services
            career
            contact
          }
        }
      }
      services: allSanityMainService(sort: { fields: order, order: ASC }) {
        edges {
          node {
            title {
              _type
              de
              en
            }
            subtitle {
              _type
              de
              en
            }
            slug {
              current
            }
          }
        }
      }
    }
  `)

  const toggleMenu = () => setShowMenu(!showMenu)

  const toggleServices = () => {
    if (!showServices && showNews) {
      setShowNews(false)
    }
    setShowServices(!showServices)
  }

  const toggleNews = () => {
    if (showServices && !showNews) {
      setShowServices(false)
    }
    setShowNews(!showNews)
  }

  const setServiceSlug = slug => {
    setShowMenu(!showMenu)
    setSlug(slug)
  }

  const setNewssSlug = slug => {
    setShowMenu(!showMenu)
    setNewsSlug(slug)
  }

  return (
    <>
      <div id="mainmenu" className={showMenu ? "menuOpen" : ""}>
        <div>
          <Link
            onClick={() => setShowMenu(false)}
            to={`/${lan ? lan : "de"}/`}
            state={{
              logo:
                language.props &&
                (language.props.path === "/en/" ||
                  language.props.path === "/de/")
                  ? true
                  : false,
            }}
            className="logo"
          >
            <img loading="lazy" src={logo} alt="img" height="58" width="257" />
          </Link>
        </div>
        <div
          className={
            showMenu && window.innerWidth < 600 ? "padding--left-0 ov-scrl" : ""
          }
        >
          <ul>
            <li className="child">
              <Link
                onClick={toggleMenu}
                partiallyActive={true}
                activeClassName="color-red"
                to={`/${lan}/about/`}
              >
                {site.siteMetadata &&
                  site.siteMetadata[lan] &&
                  site.siteMetadata[lan].aboutUs}
              </Link>
            </li>
            <li
              className={
                showServices ? "child descendant opened" : "child descendant"
              }
            >
              <Link
                onClick={(toggleMenu, toggleServices)}
                partiallyActive={true}
                className="cursor-pointer"
                activeClassName="color-red"
                to={`/${lan}/services/${slug}/`}
              >
                {site.siteMetadata &&
                  site.siteMetadata[lan] &&
                  site.siteMetadata[lan].services}
              </Link>
              <div className="child">
                <ul>
                  {services.edges.map((service, index) => (
                    <li
                      key={index}
                      data-metadescription={
                        lan === "en"
                          ? service.node.subtitle.en
                          : service.node.subtitle.de
                      }
                      className="child descendant"
                    >
                      <Link
                        key={index + 1}
                        onClick={() =>
                          setServiceSlug(service.node.slug.current)
                        }
                        partiallyActive={true}
                        activeClassName="color-red"
                        to={`/${lan}/services/${service.node.slug.current}/`}
                      >
                        {lan === "en"
                          ? service.node.title.en.split("&")[0]
                          : service.node.title.de.split("&")[0]}
                        <span className="red">&amp;</span>
                        {lan === "en"
                          ? service.node.title.en.split("&").pop()
                          : service.node.title.de.split("&").pop()}
                      </Link>
                    </li>
                  ))}
                </ul>
                <div className="metadescription" data-metadescription=""></div>
              </div>
            </li>
            <li className="child descendant">
              <Link
                onClick={toggleMenu}
                partiallyActive={true}
                activeClassName="color-red"
                to={`/${lan}/team/`}
              >
                {site.siteMetadata &&
                  site.siteMetadata[lan] &&
                  site.siteMetadata[lan].team}
              </Link>
            </li>
            <li className="child descendant">
              <Link
                onClick={toggleMenu}
                partiallyActive={true}
                activeClassName="color-red"
                to={`/${lan}/career/`}
              >
                {site.siteMetadata &&
                  site.siteMetadata[lan] &&
                  site.siteMetadata[lan].career}
              </Link>
            </li>
            <li
              className={
                showNews ? "child descendant opened" : "child descendant"
              }
            >
              <Link
                onClick={(toggleMenu, toggleNews)}
                partiallyActive={true}
                activeClassName="color-red"
                className="cursor-pointer"
                to={`/${lan}/${newsSlug}/`}
              >
                {site.siteMetadata &&
                  site.siteMetadata[lan] &&
                  site.siteMetadata[lan].news}
              </Link>
              <div className="child">
                <ul>
                  <li className="child descendant">
                    <Link
                      onClick={() => setNewssSlug("publications")}
                      partiallyActive={true}
                      activeClassName="color-red"
                      to={`/${lan}/publications/`}
                    >
                      {lan === "en" ? "Publications" : "Publikationen"}
                    </Link>
                  </li>
                  <li className="child descendant">
                    <Link
                      onClick={() => setNewssSlug("webinars")}
                      partiallyActive={true}
                      activeClassName="color-red"
                      to={`/${lan}/webinars/`}
                    >
                      {site.siteMetadata &&
                        site.siteMetadata[lan] &&
                        site.siteMetadata[lan].webinars}
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="child">
              <Link
                onClick={toggleMenu}
                partiallyActive={true}
                activeClassName="color-red"
                to={`/${lan}/contact/`}
              >
                {site.siteMetadata &&
                  site.siteMetadata[lan] &&
                  site.siteMetadata[lan].contact}
              </Link>
            </li>
            <li className="child onlyMobile flex--mobile  justify--content-center-mobile">
              <Link
                partiallyActive={true}
                activeClassName="color-red"
                onClick={
                  language.props.path
                    ? language.props.path.includes("en")
                      ? toggleMenu
                      : event => event.preventDefault()
                    : toggleMenu
                }
                state={{ language: "de" }}
                className="w--auto-mobile h--auto-mobile"
                to={
                  language.props.path
                    ? language.props.path === "/de/" ||
                      language.props.path === "/en/"
                      ? "/de/"
                      : `/de/${language.props.path.slice(4)}`
                    : "/de/"
                }
              >
                DE
              </Link>
              &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
              <Link
                partiallyActive={true}
                activeClassName="color-red"
                onClick={
                  language.props.path
                    ? language.props.path.includes("de")
                      ? toggleMenu
                      : event => event.preventDefault()
                    : toggleMenu
                }
                state={{ language: "en" }}
                className="w--auto-mobile h--auto-mobile ml--0"
                to={
                  language.props.path
                    ? language.props.path === "/de/" ||
                      language.props.path === "/en/"
                      ? "/en/"
                      : `/en/${language.props.path.slice(4)}`
                    : "/en/"
                }
              >
                EN
              </Link>
            </li>
          </ul>
        </div>
        <div className="langhash">
          <div className="d-flex">
            <Link
              partiallyActive={true}
              activeClassName="color-red"
              onClick={
                language.props.path
                  ? language.props.path.includes("en")
                    ? toggleMenu
                    : event => event.preventDefault()
                  : toggleMenu
              }
              state={{ language: "de" }}
              to={
                language.props.path
                  ? language.props.path === "/de/" ||
                    language.props.path === "/en/"
                    ? "/de/"
                    : `/de/${language.props.path.slice(4)}`
                  : "/de/"
              }
              className="de-lan-button"
            >
              DE
            </Link>
            |
            <Link
              partiallyActive={true}
              activeClassName="color-red"
              className="ml--0"
              state={{ language: "en" }}
              onClick={
                language.props.path
                  ? language.props.path.includes("de")
                    ? toggleMenu
                    : event => event.preventDefault()
                  : toggleMenu
              }
              to={
                language.props.path
                  ? language.props.path === "/de/" ||
                    language.props.path === "/en/"
                    ? "/en/"
                    : `/en/${language.props.path.slice(4)}`
                  : "/en/"
              }
              style={{ position: "sticky", zIndex: "9999", marginTop: "-30px" }}
            >
              EN
            </Link>
          </div>
        </div>
        <div id="menuOpener">
          <div
            role="button"
            tabIndex={0}
            style={{
              outline: "none",
            }}
            onClick={toggleMenu}
            onKeyDown={toggleMenu}
          >
            {showMenu ? <Close /> : <Menu />}
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
