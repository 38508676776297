import React from "react"
import { useEffect } from "react"

const Map = () => {
    function initMap() {
        const google = window.google

        if (!google) {
            return
        }
        var locations = [
            [
                "Haackschubert (Standort Offenbach am Main)",
                "50.1134384",
                "8.7547952",
                "https://www.google.com/maps/place/Hafeninsel+11,+63067+Offenbach+am+Main,+Germany",
                0,
            ],
            [
                "Haackschubert (Standort Frankfurt am Main)",
                "50.10818254250128",
                "8.675507282209049",
                "https://maps.app.goo.gl/HQUUgjQrXgXgP8sP7",
                1,
            ],
        ]

        var head = document.getElementsByTagName('head')[0];
        // Save the original method
        var insertBefore = head.insertBefore;
        // Remove Roboto from it!
        head.insertBefore = function (newElement, referenceElement) {
            if (newElement.href && newElement.href.indexOf('https://fonts.googleapis.com/css?family=Roboto') === 0) {
                return;
            }
            insertBefore.call(head, newElement, referenceElement);
        };

        var map = new google.maps.Map(document.getElementById("map"), {
            zoom: 11,
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            draggable: true,
            // maxZoom: 17,
            maxZoom: 25,
            minZoom: 5,
            center: new google.maps.LatLng(50.1134384, 8.7547952),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
        })

        var infowindow = new google.maps.InfoWindow({})

        var marker, i

        for (i = 0; i < locations.length; i++) {
            marker = new google.maps.Marker({
                position: new google.maps.LatLng(locations[i][1], locations[i][2]),
                map: map,
                title: locations[i][0],
                // icon: {
                //   url: "../images/marker.png",
                //   innerHeight: "20",
                //   outerHeight: "20",
                //   labelOrigin: new google.maps.Point(75, 32),
                //   size: new google.maps.Size(32, 32),
                //   anchor: new google.maps.Point(16, 32),
                // },
                // label: {
                //   text: locations[i][4],
                //   color: "red",
                //   fontWeight: "bold",
                // },
            })

            google.maps.event.addListener(
                marker,
                "click",
                (function (marker, i) {
                    return function () {
                        window.open(locations[i][3])
                        // window.location = locations[i][3]
                        // infowindow.setContent(locations[i][0])
                        // infowindow.open(map, marker)
                    }
                })(marker, i)
            )
        }
    }
    // const initMap = () => {
    //   const google = window.google

    //   if (!google) {
    //     return
    //   }

    //   var locations = [
    //     ["Haackschubert", "50.1134384,8.7547952"],
    //     ["Haackschubert second one", "50.105587, 8.6834863"],
    //   ]
    //   var coordsRaw2 = "50.105587, 8.6834863"
    //   let count
    //   let marker

    //   var map = new google.maps.Map(document.getElementById("map"), {
    //     zoom: 10,
    //     center: new google.maps.LatLng(50.1134384, 8.7547952),
    //     mapTypeId: google.maps.MapTypeId.ROADMAP,
    //   })

    //   var infowindow = new google.maps.InfoWindow({})

    //   for (count = 0; count < locations.length; count++) {
    //     marker = new google.maps.Marker({
    //       position: new google.maps.LatLng(
    //         locations[count][1],
    //         locations[count][2]
    //       ),
    //       map: map,
    //       title: locations[count][0],
    //     })
    //     google.maps.event.addListener(
    //       marker,
    //       "click",
    //       (function (marker, count) {
    //         return function () {
    //           infowindow.setContent(locations[count][0])
    //           infowindow.open(map, marker)
    //         }
    //       })(marker, count)
    //     )
    //   }

    //   // coordsRaw = coordsRaw.split(",")
    //   // var coord = {
    //   //   lat: parseFloat(coordsRaw[0]),
    //   //   lng: parseFloat(coordsRaw[1]),
    //   // }

    //   // var coord2 = {
    //   //   lat: parseFloat(coordsRaw2[0]),
    //   //   lng: parseFloat(coordsRaw2[1]),
    //   // }

    //   // var map = new google.maps.Map(document.getElementById("map"), {
    //   //   zoom: 11,
    //   //   center: locations,
    //   //   zoomControl: true,
    //   //   mapTypeControl: true,
    //   //   scaleControl: false,
    //   //   streetViewControl: false,
    //   //   rotateControl: false,
    //   //   fullscreenControl: false,
    //   //   draggable: true,
    //   //   maxZoom: 17,
    //   //   minZoom: 5,
    //   // })

    //   // const marker = new google.maps.Marker({
    //   //   position: coord,
    //   //   map: map,
    //   //   title: "HaackSchubert",
    //   // })

    //   // const marker2 = new google.maps.Marker({
    //   //   position: coord2,
    //   //   map: map,
    //   //   title: "HaackSchubert second",
    //   // })

    //   marker.addListener("click", () => {
    //     window.location = `https://www.google.com/maps/place/Hafeninsel+11,+63067+Offenbach+am+Main,+Germany`
    //   })

    //   marker.addListener("click", () => {
    //     window.location = `https://www.google.com/maps/place/Walter-Kolb-Stra%C3%9Fe,+60594+Frankfurt+am+Main,+Germany`
    //   })

    //   // var currCenter = map.getCenter()

    //   // google.maps.event.trigger(map, "resize")
    //   // map.setCenter(currCenter)
    // }

    useEffect(() => {
        initMap()
    }, [])

    return (
        <div className="map">
            <div id="map"></div>
        </div>
    )
}

export default Map
