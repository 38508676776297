import { propTypes } from "gatsby-plugin-image/dist/src/components/gatsby-image.server"
import React from "react"

interface Props {
  className?: string
  fill?: string
  height?: string
  width?: string
  style?: any
  onClick?: any
}

export const Email: React.FC<Props> = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 24 24"
    className={props.className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9946 21.2289C8.8838 21.2289 5.77302 21.2289 2.66225 21.2289C0.882591 21.2289 0.0144687 20.3463 0 18.5522C0 13.9222 0 9.29224 0 4.66225C0 2.88259 0.897061 2.01447 2.67671 2C8.86933 2 15.0619 2 21.269 2C23.0776 2 23.9891 2.88259 23.9891 4.70565C24.0036 9.3067 24.0036 13.9078 23.9891 18.5233C23.9891 20.3463 23.0776 21.2145 21.2546 21.2145C18.1727 21.2289 15.0764 21.2289 11.9946 21.2289ZM21.7176 6.81808C21.3558 7.00618 21.1388 7.10746 20.9507 7.23768C18.2306 8.91605 15.496 10.5944 12.7904 12.3162C12.1971 12.6924 11.7631 12.6779 11.1699 12.3017C8.49314 10.5944 5.77302 8.93052 3.06737 7.26662C2.85034 7.1364 2.61884 7.03512 2.3584 6.89043C2.32946 7.07852 2.30053 7.1798 2.30053 7.26662C2.30053 10.9561 2.31499 14.6457 2.28606 18.3352C2.28606 18.9284 2.60437 18.9139 3.02396 18.9139C8.99955 18.9139 14.9751 18.8995 20.9652 18.9284C21.5584 18.9284 21.7465 18.7548 21.7465 18.1616C21.7176 14.8627 21.732 11.5494 21.732 8.25049C21.7176 7.81643 21.7176 7.4113 21.7176 6.81808ZM2.47415 4.41627C2.70565 4.61884 2.79246 4.72012 2.87928 4.77799C5.77302 6.58658 8.68124 8.39518 11.5895 10.1604C11.8065 10.2906 12.2405 10.2327 12.472 10.088C14.816 8.67008 17.1454 7.22321 19.4749 5.77633C20.1115 5.38568 20.7337 4.98056 21.3703 4.57543C21.3414 4.51756 21.3269 4.47415 21.298 4.41627C15.1053 4.41627 8.89827 4.41627 2.47415 4.41627Z"
      fill="#E40036"
    />
  </svg>
)

export const Phone: React.FC<Props> = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.9387 18.9645C21.9387 19.5394 21.952 20.1277 21.9387 20.7027C21.9253 21.6253 21.5509 22.013 20.6283 21.9997C16.7908 21.9194 13.2609 20.8498 10.1187 18.6435C5.90684 15.7019 3.32624 11.6639 2.32342 6.64977C2.09611 5.53997 2.056 4.40344 2.00251 3.2669C1.9624 2.45127 2.40364 2.0234 3.21928 2.01003C4.42266 1.99666 5.62605 1.99666 6.82944 2.01003C7.59159 2.0234 7.89912 2.3443 8.00609 3.07971C8.19328 4.37669 8.40722 5.66031 8.63453 6.9573C8.7415 7.53225 8.62116 7.98687 8.17991 8.40137C7.52474 9.00306 6.92304 9.67161 6.28123 10.2733C6.00044 10.5407 5.98707 10.7547 6.17426 11.0756C7.77878 13.9503 9.99837 16.1967 12.8865 17.7878C13.2609 17.9884 13.4748 17.9215 13.7422 17.6407C14.3172 17.0257 14.959 16.4641 15.5206 15.8356C15.9886 15.3142 16.51 15.2473 17.1518 15.3677C18.2616 15.5682 19.3714 15.7688 20.4946 15.8891C21.6712 16.0095 21.952 16.2635 21.952 17.4803C21.952 17.9884 21.952 18.4831 21.9387 18.9645ZM4.06165 4.25636C4.36918 5.70043 4.66334 7.03752 4.9575 8.38799C5.03773 8.38799 5.10459 8.38799 5.18481 8.38799C5.50572 8.05372 5.83999 7.73282 6.13415 7.3718C6.25449 7.23809 6.3882 7.02415 6.37483 6.8637C6.24112 5.99459 6.05393 5.12547 5.89347 4.24299C5.33189 4.25636 4.81042 4.25636 4.06165 4.25636ZM19.7726 19.9004C19.7726 19.3522 19.7458 19.0313 19.7726 18.697C19.8127 18.2558 19.6522 18.0552 19.1976 18.0151C18.7296 17.975 18.275 17.868 17.8204 17.7744C16.8309 17.5739 16.1089 17.9616 15.6008 19.058C16.978 19.3388 18.3018 19.6063 19.7726 19.9004Z"
      fill="#E40036"
    />
  </svg>
)

export const MyLocation: React.FC<Props> = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.9604 10.9187C23.9868 11.1033 24 11.2088 24 11.3143C24 11.8418 24 12.3692 24 13.0022C23.4198 13.0022 22.8791 13.0154 22.3516 13.0022C21.956 12.989 21.7846 13.0945 21.7055 13.5297C20.8747 17.7099 17.6835 20.8615 13.4901 21.7187C13.3187 21.7582 13.0945 22.022 13.0681 22.2066C13.0154 22.7473 13.0418 23.3011 13.0418 23.9209C12.6857 23.9341 12.356 23.9604 12.0396 23.9604C11.7099 23.9736 11.3802 23.9604 10.9451 23.9604C10.9451 23.3538 10.9319 22.8 10.9451 22.233C10.9582 21.8901 10.8396 21.7451 10.4703 21.6791C6.32967 20.9275 3.04615 17.6044 2.24176 13.4506C2.2022 13.2791 1.92527 13.0418 1.74066 13.0286C1.21319 12.9758 0.672527 13.0154 0 13.0154C0 12.6593 0 12.3429 0 12.0264C0 11.6967 0 11.367 0 10.9319C0.593407 10.9319 1.16044 10.9187 1.72747 10.9319C2.07033 10.9451 2.22857 10.8396 2.29451 10.4703C2.99341 6.46154 6.34286 3.01978 10.3253 2.28132C10.8923 2.17582 11.011 1.96484 10.9714 1.47692C10.9451 1.0022 10.9714 0.527473 10.9714 0.0131868C11.6703 0 12.3033 0 13.0549 0C13.0549 0.567033 13.0681 1.12088 13.0549 1.68791C13.0418 2.01758 13.1341 2.17582 13.5165 2.24176C17.6308 3.01978 20.9011 6.26374 21.7055 10.3648C21.7978 10.8132 21.956 10.9582 22.3912 10.9319C22.8791 10.8923 23.3802 10.9187 23.9604 10.9187ZM11.9868 4.23297C7.66154 4.24615 4.24615 7.67473 4.24615 11.9736C4.25934 16.2857 7.71429 19.7275 12 19.7011C16.3385 19.6747 19.7143 16.2725 19.7143 11.9604C19.7011 7.63516 16.2857 4.21978 11.9868 4.23297Z"
      fill="#E40036"
    />
    <path
      d="M12 7.63517C14.3736 7.64836 16.3912 9.66594 16.3517 12.0132C16.3121 14.3736 14.3473 16.3121 12 16.3121C9.61319 16.3121 7.67473 14.3473 7.68792 11.9341C7.68792 9.56044 9.63957 7.62198 12 7.63517Z"
      fill="#E40036"
    />
  </svg>
)

export const Parking: React.FC<Props> = props => (
  <svg
    width={props.width}
    height={props.height}
    style={{ marginLeft: "2px" }}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={props.onClick}
  >
    <path
      d="M3.03635 11.9831C3.03635 8.29376 3.05239 4.60443 3.02031 0.915098C3.02031 0.209313 3.21279 -0.0152472 3.91858 0.000793357C6.7738 0.0328745 9.64506 0.000789441 12.5003 0.01683C16.8312 0.0328706 20.28 3.64199 20.2639 8.13335C20.2479 12.4322 16.751 15.9291 12.4522 15.9291C11.3293 15.9291 10.2065 15.9611 9.08364 15.913C8.45806 15.897 8.24953 16.0895 8.24953 16.7311C8.28161 18.7843 8.26557 20.8535 8.26557 22.9067C8.26557 23.9814 8.26557 23.9814 7.14273 23.9814C6.05197 23.9814 4.94518 23.9494 3.85442 23.9975C3.16467 24.0296 2.92406 23.7569 3.02031 23.1152C3.02031 23.0832 3.02031 23.0671 3.02031 23.035C3.03635 19.3618 3.03635 15.6724 3.03635 11.9831ZM8.28161 8.00503C8.28161 8.69477 8.29765 9.40056 8.28161 10.0903C8.26557 10.5715 8.42598 10.764 8.92324 10.748C10.1423 10.7159 11.3774 10.764 12.5965 10.7319C14.1204 10.6998 15.2593 9.52889 15.2753 8.02108C15.2913 6.5293 14.1525 5.31022 12.6446 5.2621C11.3935 5.21398 10.1423 5.26209 8.89115 5.24605C8.44202 5.24605 8.26557 5.40646 8.28161 5.8556C8.29765 6.56139 8.28161 7.2832 8.28161 8.00503Z"
      fill="#E40036"
    />
  </svg>
)

export const DropdownArrow: React.FC<Props> = props => (
  <svg
    style={{ marginRight: "12px", position: "absolute" }}
    width="22"
    height="22"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22.9091C18.0249 22.9091 22.9091 18.0249 22.9091 12C22.9091 5.97508 18.0249 1.09091 12 1.09091C5.97508 1.09091 1.09091 5.97508 1.09091 12C1.09091 18.0249 5.97508 22.9091 12 22.9091ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="black"
    />
    <path d="M7 10L12.4545 15.4545L17.9091 10H7Z" fill="black" />
  </svg>
)

export const Search: React.FC<Props> = props => (
  <svg
    style={{ marginRight: "15px", position: "absolute" }}
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.2332 21.8602L17.5212 15.9194C18.9898 14.1735 19.7945 11.9768 19.7945 9.68999C19.7945 4.34701 15.4475 0 10.1045 0C4.76156 0 0.414551 4.34701 0.414551 9.68999C0.414551 15.033 4.76156 19.38 10.1045 19.38C12.1104 19.38 14.0218 18.775 15.6561 17.6265L21.4115 23.6124C21.6521 23.8622 21.9756 24 22.3224 24C22.6505 24 22.9619 23.8749 23.1982 23.6474C23.7004 23.1641 23.7164 22.3628 23.2332 21.8602ZM10.1045 2.52782C14.0538 2.52782 17.2667 5.74069 17.2667 9.68999C17.2667 13.6393 14.0538 16.8522 10.1045 16.8522C6.15524 16.8522 2.94237 13.6393 2.94237 9.68999C2.94237 5.74069 6.15524 2.52782 10.1045 2.52782Z"
      fill={props.fill ? props.fill : "black"}
    />
  </svg>
)

export const LeftArrow: React.FC<Props> = props => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 24 24"
    style={props.style}
    // fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0674 12.0924C10.9596 11.939 10.9081 11.8252 10.9175 11.7016V11.6966V11.6917C10.9081 11.5729 10.9596 11.4542 11.0674 11.3009C12.1453 9.79696 13.2184 8.29306 14.301 6.79412C14.4322 6.61108 14.4275 6.43298 14.3057 6.24994C14.0714 5.9086 13.8089 5.91849 13.4949 6.26973C12.0703 7.88741 10.655 9.49519 9.23506 11.098C9.07104 11.286 8.99137 11.4888 9.00074 11.6966V11.7016V11.7065C8.99605 11.9093 9.07104 12.1171 9.23506 12.3051C10.655 13.9129 12.075 15.5207 13.4949 17.1284C13.8089 17.4846 14.0714 17.4896 14.3057 17.1482C14.4322 16.9652 14.4322 16.7921 14.301 16.6041C13.2184 15.1002 12.1453 13.5963 11.0674 12.0924Z"
      fill={props.fill ? props.fill : "black"}
    />
  </svg>
)

export const LeftBackArrow: React.FC<Props> = props => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 24 24"
      style={
        props.style ? props.style : { marginLeft: "-8px", marginTop: "1px" }
      }
      // fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0674 12.0924C10.9596 11.939 10.9081 11.8252 10.9175 11.7016V11.6966V11.6917C10.9081 11.5729 10.9596 11.4542 11.0674 11.3009C12.1453 9.79696 13.2184 8.29306 14.301 6.79412C14.4322 6.61108 14.4275 6.43298 14.3057 6.24994C14.0714 5.9086 13.8089 5.91849 13.4949 6.26973C12.0703 7.88741 10.655 9.49519 9.23506 11.098C9.07104 11.286 8.99137 11.4888 9.00074 11.6966V11.7016V11.7065C8.99605 11.9093 9.07104 12.1171 9.23506 12.3051C10.655 13.9129 12.075 15.5207 13.4949 17.1284C13.8089 17.4846 14.0714 17.4896 14.3057 17.1482C14.4322 16.9652 14.4322 16.7921 14.301 16.6041C13.2184 15.1002 12.1453 13.5963 11.0674 12.0924Z"
        fill={props.fill ? props.fill : "black"}
      />
    </svg>
  )
}

export const LeftArroww: React.FC<Props> = props => {
  return (
    <svg
      width="35"
      height="35"
      onClick={props.onClick}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0674 12.0924C10.9596 11.939 10.9081 11.8252 10.9175 11.7016V11.6966V11.6917C10.9081 11.5729 10.9596 11.4542 11.0674 11.3009C12.1453 9.79696 13.2184 8.29306 14.301 6.79412C14.4322 6.61108 14.4275 6.43298 14.3057 6.24994C14.0714 5.9086 13.8089 5.91849 13.4949 6.26973C12.0703 7.88741 10.655 9.49519 9.23506 11.098C9.07104 11.286 8.99137 11.4888 9.00074 11.6966V11.7016V11.7065C8.99605 11.9093 9.07104 12.1171 9.23506 12.3051C10.655 13.9129 12.075 15.5207 13.4949 17.1284C13.8089 17.4846 14.0714 17.4896 14.3057 17.1482C14.4322 16.9652 14.4322 16.7921 14.301 16.6041C13.2184 15.1002 12.1453 13.5963 11.0674 12.0924Z"
        fill={props.fill ? props.fill : "white"}
      />
    </svg>
  )
}

export const RightArroww: React.FC<Props> = props => (
  <svg
    onClick={props.onClick}
    width={"35"}
    height={"35"}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3326 12.0924C12.4404 11.939 12.4919 11.8252 12.4825 11.7016V11.6966V11.6917C12.4919 11.5729 12.4404 11.4542 12.3326 11.3009C11.2547 9.79696 10.1815 8.29306 9.099 6.79412C8.96778 6.61108 8.97247 6.43298 9.09431 6.24994C9.32863 5.9086 9.59107 5.91849 9.90505 6.26973C11.3297 7.88741 12.745 9.49519 14.1649 11.098C14.329 11.286 14.4086 11.4888 14.3993 11.6966V11.7016V11.7065C14.4039 11.9093 14.329 12.1171 14.1649 12.3051C12.745 13.9129 11.325 15.5207 9.90505 17.1284C9.59107 17.4846 9.32863 17.4896 9.09431 17.1482C8.96778 16.9652 8.96778 16.7921 9.099 16.6041C10.1815 15.1002 11.2547 13.5963 12.3326 12.0924Z"
      fill={props.fill ? props.fill : "white"}
    />
  </svg>
)

export const RightArrow: React.FC<Props> = props => (
  <svg
    style={props.style ? props.style : { marginLeft: "-6px" }}
    // style={{ marginLeft: "-6px" }}
    width={props.width ? props.width : "22"}
    height={props.height ? props.height : "22"}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3326 12.0924C12.4404 11.939 12.4919 11.8252 12.4825 11.7016V11.6966V11.6917C12.4919 11.5729 12.4404 11.4542 12.3326 11.3009C11.2547 9.79696 10.1815 8.29306 9.099 6.79412C8.96778 6.61108 8.97247 6.43298 9.09431 6.24994C9.32863 5.9086 9.59107 5.91849 9.90505 6.26973C11.3297 7.88741 12.745 9.49519 14.1649 11.098C14.329 11.286 14.4086 11.4888 14.3993 11.6966V11.7016V11.7065C14.4039 11.9093 14.329 12.1171 14.1649 12.3051C12.745 13.9129 11.325 15.5207 9.90505 17.1284C9.59107 17.4846 9.32863 17.4896 9.09431 17.1482C8.96778 16.9652 8.96778 16.7921 9.099 16.6041C10.1815 15.1002 11.2547 13.5963 12.3326 12.0924Z"
      fill={props.fill ? props.fill : "white"}
    />
  </svg>
)

export const Menu: React.FC<Props> = props => (
  <svg
    width="30"
    height="24"
    style={{ outline: "none" }}
    viewBox="0 0 30 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="2" width="30" height="4" fill="#E40C36" />
    <rect y="10" width="30" height="4" fill="#E40C36" />
    <rect y="18" width="30" height="4" fill="#E40C36" />
  </svg>
)

export const Close: React.FC<Props> = props => (
  <svg
    style={{ outline: "none" }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_8_63)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5872 4.61665L19.3245 2.35391L11.9706 9.70782L4.61665 2.35391L2.35391 4.61665L9.70782 11.9706L2.35391 19.3245L4.61665 21.5872L11.9706 14.2333L19.3245 21.5872L21.5872 19.3245L14.2333 11.9706L21.5872 4.61665Z"
        fill="#E40C36"
      />
    </g>
    <defs>
      <clipPath id="clip0_8_63">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
